import { regexMobile } from './regex';

const formatMobileWithDash = (/** @type {string} */ mobile) => {
  if (regexMobile.test(mobile)) {
    return `0${mobile
      .slice(1)
      .match(/.{1,3}/g)
      .join('-')}`;
  } else return '';
};

export default formatMobileWithDash;
