/**
 * LM-LIFF-01-1 綁定流程-確認門號 https://lmliff.cht.com.tw/signed
 */
import { useMemo } from 'react';
import { Link } from 'gatsby';

import { useGetUserInfo } from '@/apis/hooks';
import pngMainBanner from '@/assets/images/img-banner@3x.png';
import pngSimAndLine from '@/assets/images/img-sim-and-line@3x.png';
import { CancelButton, PrimaryButton } from '@/components/StandardButton';
import ProgressiveImage from '@/patterns/ProgressiveImage';
import formatMobileWithDash from '@/utils/formatMobileWithDash';
import { styled } from '@/utils/styledComponents';

const ContentWrapper = styled.div`
  padding: 0 16px 12px 16px;
`;

const ImageSimAndLine = styled.img`
  display: block;
  margin: 40px auto 0 auto;
`;

const PhoneNumber = styled.div`
  margin-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #111111;
`;

const ConfirmText = styled.div`
  margin-top: 4px;
  margin-bottom: 58px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #111111;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
`;

const SignedPage = () => {
  const { userInfo } = useGetUserInfo();

  const mobileNumber = useMemo(
    () => formatMobileWithDash(userInfo.mobile),
    [userInfo.mobile]
  );

  return (
    <div>
      <ProgressiveImage src={pngMainBanner} width="100%" />
      <ContentWrapper>
        <ImageSimAndLine src={pngSimAndLine} width="162px" />
        <PhoneNumber>{mobileNumber}</PhoneNumber>
        <ConfirmText>請問您是否要用此門號進行綁定？</ConfirmText>
        <Footer>
          <Link to="/signed/legacy-number/validate">
            <PrimaryButton type="primary">是，確定用此門號</PrimaryButton>
          </Link>
          <Link to="/signed/new-number/apply">
            <CancelButton>否，改用別的門號</CancelButton>
          </Link>
        </Footer>
      </ContentWrapper>
    </div>
  );
};

export default SignedPage;
